@import "./assets/common";
@import './assets/fonts/inter/style.scss';

body {
  font-family: Inter, Roboto, Helvetica, Arial, sans-serif;  // override font-family của ng-zorro-antd
  line-height: 1.2;
  background-color: #F0F2F5;
  color: $colorBlack;
}
.top5 {
  margin-top: 5px;
}
.top10 {
  margin-top: 10px;
}
.top15 {
  margin-top: 15px;
}
.top20 {
  margin-top: 20px;
}
.top25 {
  margin-top: 25px;
}
.bottom5 {
  margin-bottom: 5px;
}
.bottom8 {
  margin-bottom: 8px;
}
.bottom10 {
  margin-bottom: 10px;
}
.bottom15 {
  margin-bottom: 15px;
}
.bottom20 {
  margin-bottom: 20px;
}
.right5 {
  margin-right: 5px;
}
.right10 {
  margin-right: 10px;
}
.right15 {
  margin-right: 15px;
}
.right20 {
  margin-right: 20px;
}
.left5 {
  margin-left: 5px;
}
.left10 {
  margin-left: 10px;
}
.left15 {
  margin-left: 15px;
}
.left20 {
  margin-left: 20px;
}
.visible {
  opacity: 1;
}
.invisible {
  opacity: 0;
}
.hidden {
  display: none;
}
.flex {
  display: flex;
}
.flex-column {
  display: flex;
  flex-direction: column;
}
.flex-space-between {
  display: flex;
  justify-content: space-between;
}
.flex-row-reverse {
  display: flex;
  flex-direction: row-reverse;
}
.avatar {
  width: 50px;
  height: 50px;
  object-fit: cover;
  padding: 0px;
  border-radius: 50% !important;
  transition: border 0.3s ease;
}
.avatar-white {
  border: 1px solid $colorBorder;
}
.avatar30 {
  width: 30px;
  height: 30px;
}
.center-children {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.center-vertical {
  display: flex;
  align-items: center;
}
.disabled:hover {
  cursor: not-allowed;
}
div.disabled {
  cursor: not-allowed;
  pointer-events: none;
}
div.disabled.btn {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
}
.separator {
  background-color: $colorBorder;
}
.separator.h {
  width: 100%;
  height: 1px;
}
.separator.v {
  width: 1px;
  height: 100%;
}
.text-center {
  text-align: center;
}
.warning {
  color: $colorWarning;
}
.primary {
  color: $colorPrimary;
}
.text-secondary {
  color: #A1A1AA;
}
.small-text {
  font-size: 12px;
}
.nodata {
  width: 100%;
  height: 100%;
  min-height: 200px;
  max-height: 500px;
  font-size: 24px;
  color: $colorGrey;
  display: flex;
  align-items: center;
  justify-content: center;
}
.label-mark-required {
  color: $colorDanger;
}
.label-mark-required::after {
  padding-left: 4px;
  content: "*";
}
.full-w { width: 100%; }
.form-label {
  font-weight: 500;
  white-space: nowrap;  // force to be single line
}
.flex1 { flex: 1; }
.gap10 { gap: 10px; }

.f20 { font-size: 20px; }

.clickable {
  cursor: pointer;
  // color: $colorBodyText;
}
.clickable.danger {
  color: $colorDanger;
}
.clickable:not(.disabled):hover {
  opacity: 0.65;
}
.clickable:not(.disabled):active {
  opacity: 0.35;
}

.semibold {
  font-weight: 600;
}
.bold {
  font-weight: 700;
}

.job-status {
  align-items: center;
  padding: 4px 10px;
  border-radius: 20px;
  transition: all 0.3s ease;
  
  &.created {
    color: #6B7280;
    background: rgba(107, 114, 128, 0.1);
    border: 1px solid rgba(107, 114, 128, 0.2);
  }

  &.inProgress {
    color: #2563EB;
    background: rgba(37, 99, 235, 0.1);
    border: 1px solid rgba(37, 99, 235, 0.2);
  }

  &.completed {
    color: #059669;
    background: rgba(5, 150, 105, 0.1);
    border: 1px solid rgba(5, 150, 105, 0.2);
  }

  &.canceled {
    color: #DC2626;
    background: rgba(220, 38, 38, 0.1);
    border: 1px solid rgba(220, 38, 38, 0.2);
  }
}

.icon:hover {
  cursor: pointer;
  opacity: 0.6;
}